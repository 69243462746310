<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="员工">
        <el-input v-model="filter.filter" placeholder="姓名、编号、拼音" clearable></el-input>
      </el-form-item>
      <el-form-item label="显示停用">
        <el-switch v-model="filter.showAll"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-plus" @click="item = {}" type="primary">添加</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="data"
      stripe
      border
      :row-class-name="tableRowClassName"
      @row-dblclick="editItem"
    >
      <el-table-column label="员工编号" prop="employeeCode" width="80" show-overflow-tooltip></el-table-column>
      <el-table-column label="员工姓名" prop="employeeName" show-overflow-tooltip></el-table-column>
      <el-table-column label="借款余额" width="80" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.balance|thousands}}</template>
      </el-table-column>
      <el-table-column label="联系电话" prop="tel" show-overflow-tooltip></el-table-column>
      <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" width="65">
        <template slot-scope="scope">
          <el-tag type="danger" effect="dark" @click="removeItem(scope.row)">删除</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 50, 100, 500]"
      @size-change="getData(1)"
      @current-change="getData()"
      :current-page.sync="pageIndex"
      :page-size.sync="pageSize"
      :total="pageTotal"
    ></el-pagination>

    <div>
      <add-edit :item="item" @success="getData()"></add-edit>
    </div>
  </div>
</template>

<script>
import AddEdit from "@/views/employee/Item";

export default {
  components: {
    AddEdit
  },
  data() {
    return {
      filter: {},
      data: [],
      pageIndex: 1,
      pageSize: 10,
      pageTotal: 0,

      item: {}
    };
  },

  watch: {
    filter: {
      handler: function(v) {
        this.getData(1);
      },
      deep: true
    }
  },
  activated() {
    this.getData();
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.isStop) {
        return "warning-row";
      } else {
        return "";
      }
    },
    getData(pageIndex) {
      if (pageIndex) {
        this.pageIndex = pageIndex;
      }
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      let params = {
        ...this.filter,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      };
      this.$get("Employee/GetAllEmployee", params)
        .then(r => {
          let data = r;
          this.data = data.list;
          this.pageTotal = data.pageTotal;
        })
        .finally(() => {
          loading.close();
        });
    },
    editItem(row) {
      this.item = {
        id: row.id
      };
    },
    removeItem(row) {
      this.$confirm("将删除【" + row.employeeName + "】, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$post("Employee/Delete?id=" + row.id).then(r => {
            this.$message.success("操作成功！");
            this.$store.commit("removeBaseInfo", {
              info: "Employee",
              id: row.id
            });
            this.getData();
          });
        })
        .catch(() => {});
    }
  }
};
</script>

